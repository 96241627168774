///////////////////////////////////////////////////////////////////////////////////MODULES
import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const ChatWidget = () => (
  <FloatingWhatsApp
    phoneNumber={c.chat.tel}
    accountName={c.chat.person}
    statusMessage={c.chat.status}
    chatboxStyle={{ boxShadow: "0 0 20px rgba(9, 11, 14, 0.5)" }}
    chatMessage={c.chat.message}
    allowEsc
    allowClickAway
    avatar={c.chat.avatar}
    style={{ zIndex: 20 }}
  />
);

export default ChatWidget;
