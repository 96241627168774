///////////////////////////////////////////////////////////////////////////////////MODULES
import { Banner as CustomBanner } from "@ibiliaze/reactstrap";
import { Section } from "@ibiliaze/react-base";
import { ScaleIn } from "@ibiliaze/react-intersect";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const Banner = () =>
  !!c.banner && (
    <ScaleIn>
      <Section className="custom-page p-t-l p-b-l">
        <CustomBanner {...c.banner} />
      </Section>
    </ScaleIn>
  );

export default Banner;
