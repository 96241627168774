import { scroll, IOS17Filled } from "@ibiliaze/react-base";
import constants from "@ibiliaze/react-constants";
import { config } from "./config";

export const env = process.env;

const c = constants(
  config({
    scroll,
    IOS17Filled: ({ name, size, color }) => (
      <IOS17Filled name={name} size={size} color={color} />
    ),
  })
);

export default c;
