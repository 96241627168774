export const config = ({ scroll = () => {}, IOS17Filled = () => {} }) => {
  const configObj = {
    // GENERAL INFO
    name: "Kochal Barber",
    domain: "kochalbarber.co.uk",
    slogan: "Experience Barber Excellence",
    api: { apiUrl: "https://www.kochalbarber.co.uk", apiDevUrl: "http://localhost:5001" },

    // CONTACT
    tel: { tel1: "07554 454450" },
    email: { email1: "kochalbarber@gmail.com" },

    // CHAT
    chat: {
      showWidget: true,
      avatar: "/img/chat.jpg",
      person: "Onur",
      tel: "07554454450",
      message: `Hello there! \n \nHow can we help?`,
      status: `Typically replies within a few minutes`,
    },

    // SOCIALS
    socials: {
      instagram: "kochalbarber",
    },

    // TYPE EFFECTS
    textType: ["Skin Fade", "Beards"],

    // HEADER
    nav: {
      blur: "1px",
      logo: "",
      dark: true,
      styles: { height: "40px" },
      fadeOnPaths: ["/"],
    },

    // MAIN PIC
    mainPics: {
      main: {
        carousel: [
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997061/production/Kochal%20Barber/YGS_0200-min_qladmt.jpg",
            altText: "",
            key: 1,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997062/production/Kochal%20Barber/YGS_0214-min_yayfjj.jpg",
            altText: "",
            key: 2,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997061/production/Kochal%20Barber/YGS_0211-min_pwemr2.jpg",
            altText: "",
            key: 3,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997060/production/Kochal%20Barber/YGS_0162-min_qie0zm.jpg",
            altText: "",
            key: 4,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997060/production/Kochal%20Barber/YGS_0149-min_aobbxa.jpg",
            altText: "",
            key: 5,
          },
          {
            src: "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997061/production/Kochal%20Barber/YGS_0213-min_z1j42d.jpg",
            altText: "",
            key: 6,
          },
        ],
      },
      copy1: {
        imgPath:
          "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997061/production/Kochal%20Barber/YGS_0182-min_pecrtj.jpg",
        parallax: false,
        noShadow: true,
        imgBottom: "0%",
        sloganCenter: true,
        height: "100vh",
        mobileHeight: "100vh",
        slogan:
          "At Kochal Barber, we believe that a great haircut starts with a solid foundation. Our barbers are trained in the latest techniques and are equipped with the best tools to ensure you receive the perfect cut every time. Our fundamental principles include attention to detail, comfort, and a personalized experience for every customer.",
      },
    },

    // WORKING HOURS
    workingHrs: {
      days: {
        Mon: { start: 32400000, end: 64800000 },
        Tue: { start: 0, end: 0 },
        Wed: { start: 32400000, end: 64800000 },
        Thu: { start: 32400000, end: 64800000 },
        Fri: { start: 32400000, end: 64800000 },
        Sat: { start: 32400000, end: 64800000 },
        Sun: { start: 0, end: 0 },
      },
      interval: 1800000,
    },

    // INFOS
    infos: {
      infos1: [
        {
          header: "Master Barber",
          body: "Our skilled master barbers bring precision and style to every haircut, ensuring you leave looking and feeling your best.",
          icon: (_) => IOS17Filled({ name: "building", size: "50" }),
          img: "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997062/production/Kochal%20Barber/YGS_0224-min_jqbiud.jpg",
          alt: "infos1-1",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
        {
          header: "Scalp Micro Pigmintation",
          body: "Experience the transformative art of scalp micro pigmentation, a non-invasive solution for a fuller, natural-looking hairline.",
          icon: (_) => IOS17Filled({ name: "home", size: "50" }),
          img: "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997062/production/Kochal%20Barber/YGS_0228-min_pl9ycy.jpg",
          alt: "infos1-2",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
        {
          header: "Out of Work Hours",
          body: "We understand your busy schedule, which is why we offer convenient out-of-work hours, so you can get that fresh haircut anytime it suits you.",
          icon: (_) => IOS17Filled({ name: "money", size: "50" }),
          img: "https://res.cloudinary.com/dgyewim8j/image/upload/v1732997063/production/Kochal%20Barber/YGS_0238-min_a1bcqm.jpg",
          alt: "infos1-3",
          onClick: (navigate) => scroll(document.getElementById("album-section")?.offsetTop),
        },
      ],
      infos2: [
        {
          header: "Book On The Go",
          body: "With Kochal Barber, customers have the option to easily book appointments either through their mobile device or desktop computer. On the go? Simply visit our mobile-friendly website and schedule your appointment in just a few taps. Prefer to use your desktop? No problem, visit our website and follow the simple steps to secure your appointment time. Either way, booking with Kochal Barber has never been easier and more convenient.",
          icon: (_) => IOS17Filled({ name: "book", size: "40" }),
        },
      ],
    },

    // REVIEWS
    reviews: [
      {
        title: "Christopher Garbushian",
        body: " Onur is an expert barber and hair stylist. He takes the time to actually look at your face shape and see what works best for you. He really goes above and beyond. It’s the best haircut I’ve had in London hands down.",
      },
      {
        title: "Benjamin Mortimer",
        body: "Love this place. Great tunes, relaxing vibe, and real attention to detail in the haircuts. Can recommend getting a proper shave here too. Really reasonable prices.",
      },
      {
        title: "Anthony Romero Perez",
        body: "My go to barber in the area! Very professional and accommodating, always cuts my hair consistently every time and great guy to have a chat with. First haircut I just showed him a picture and got exactly what was shown for a very reasonable price.",
      },
      {
        title: "Piotr Kijo",
        body: "Best barber in the area, owner is very experienced and takes pride in his work. Never have to worry about getting a bad haircut again.",
      },
      {
        title: "Mike Lane-Evans",
        body: "This is the best barbers I've found in Hackney! Onur is always so friendly- he seems to know everyone in the area. Every time I get a haircut he waves to people walking by. There are a few business owners on Chatsworth Road who are creating a really great community feel. Onur is at the heart of that!",
      },
      {
        title: "pietro paris",
        body: "Fantastic barber! Professional, quick, and, finally, someone who really gets how my hair works! Also,a very reasonable price!",
      },
      {
        title: "Yigit Gunel",
        body: "Great haircut, wonderful place to be in. Nice details with cool interior ! + Always good music.",
      },
    ],

    extras: {
      albums: [
        {
          imgSrc: "/img/albums/1/1.jpeg",
        },
        {
          imgSrc: "/img/albums/1/4.jpeg",
        },
        {
          imgSrc: "/img/albums/1/5.jpeg",
        },
        {
          imgSrc: "/img/albums/1/6.jpeg",
        },
        {
          imgSrc: "/img/albums/1/7.jpeg",
        },
        {
          imgSrc: "/img/albums/1/8.jpeg",
        },
        {
          imgSrc: "/img/albums/1/10.jpeg",
        },
        {
          imgSrc: "/img/albums/1/11.jpeg",
        },
      ],
    },
  };

  return configObj;
};
