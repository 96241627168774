///////////////////////////////////////////////////////////////////////////////////MODULES
import { useNavigate } from "react-router-dom";
import { Col, Row } from "@ibiliaze/reactstrap";
import { Section } from "@ibiliaze/react-base";
import { FadeIn, TransformYDown } from "@ibiliaze/react-intersect";
import { Multirow } from "@ibiliaze/react-multirow";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const About = () => {
  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <Section id="about-section" className="custom-page p-t-xl">
      <FadeIn>
        <h1 className="t-align-c">What We Do</h1>
      </FadeIn>

      <Row>
        {c.infos.infos1.map((a, i) => (
          <Col key={i} md={4}>
            <TransformYDown>
              <Multirow
                img={a.img}
                alt={a.alt}
                header={a.header}
                body={a.body}
                onClick={(_) => a.onClick(navigate)}
              />
            </TransformYDown>
          </Col>
        ))}
      </Row>
    </Section>
  );
};

export default About;
