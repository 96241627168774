///////////////////////////////////////////////////////////////////////////////////MODULES
import { Footer as CustomFooter } from "@ibiliaze/reactstrap";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
import { resolveHost } from "../../utils/resolveEnv";
//////////////////////////////////////////////////////////////////////////////PACKAGE JSON
import packageJson from "../../../package.json";
//////////////////////////////////////////////////////////////////////////////////////////

const Footer = () => {
  // JSX
  return (
    <CustomFooter
      title={c.name}
      textButtons={[
        {
          title: `📞 ${c?.tel.tel1}`,
          onClickHandler: (_) => (window.location.href = `tel:${c?.tel.tel1}`),
        },
        {
          title: `📧 ${c?.email.email1}`,
          onClickHandler: (_) => (window.location.href = `mailto:${c?.email.email1}`),
        },
      ]}
      navLinks={[
        {
          title: "Privacy Policy",
          href: resolveHost() + "/privacy-policy",
        },
        {
          title: "Terms & Conditions",
          href: resolveHost() + "/terms-conditions",
        },
        {
          title: "Disclaimer",
          href: resolveHost() + "/disclaimer",
        },
      ]}
      miniTexts={[`Version: ${packageJson.version}`]}
    />
  );
};

export default Footer;
