///////////////////////////////////////////////////////////////////////////////////MODULES
import { Table } from "@ibiliaze/reactstrap";
import { FadeIn, TransformYDown } from "@ibiliaze/react-intersect";
import { Section } from "@ibiliaze/react-base";
//////////////////////////////////////////////////////////////////////////////////////////

const Pricing = () => {
  // JSX
  return (
    <>
      <Section className="custom-page p-t-xl p-b-xl">
        <FadeIn>
          <h1 className="t-align-c">Pricing</h1>
        </FadeIn>
        <TransformYDown>
          <Table borderless className="pricing">
            <tbody>
              <tr>
                <th scope="row">Clipper Haircut for SHORT Hair</th>
                <td>25 mins</td>
                <td>£24.00</td>
              </tr>
              <tr>
                <th scope="row">
                  Technical Scissor Cut for Short Length, Wash & Style, (this is NOT a FULL scissor cut or
                  SKINFADE)
                </th>
                <td>30 mins</td>
                <td>£30.00</td>
              </tr>
              <tr>
                <th scope="row">Skinfade -Haircut, Wash & Style</th>
                <td>30-45 mins</td>
                <td>£32.00</td>
              </tr>
              <tr>
                <th scope="row">SKIN FADE BACK & SIDES ONLY</th>
                <td>30 mins</td>
                <td>£20.00</td>
              </tr>
              <tr>
                <th scope="row">FULL Scissor cut for (LONG/MED Length), Wash & Style</th>
                <td>30-45 mins</td>
                <td>£40.00</td>
              </tr>
              <tr>
                <th scope="row">Re-Styling (Long to Short Length), Wash & Style-this is not SKINFADE</th>
                <td>30 mins</td>
                <td>£30.00</td>
              </tr>
              <tr>
                <th scope="row">FULL Scissor Cut (SHORT Length) - Wash & Style</th>
                <td>30-45 mins</td>
                <td>£32.00</td>
              </tr>
              <tr>
                <th scope="row">MULLET Re-Styling- Wash & Style</th>
                <td>30 mins</td>
                <td>£30.00</td>
              </tr>
              <tr>
                <th scope="row">Men - Re-Wash& Style- No Cut</th>
                <td>5 mins</td>
                <td>£10.00</td>
              </tr>
              <tr>
                <th scope="row">HEAD SHAVE</th>
                <td>20 mins</td>
                <td>£20.00</td>
              </tr>
              <tr>
                <th scope="row">LUXURY WET SHAVE</th>
                <td>30 mins</td>
                <td>£30.00</td>
              </tr>
              <tr>
                <th scope="row">Student Cut</th>
                <td>30-45 mins</td>
                <td>£25.00</td>
              </tr>
              <tr>
                <th scope="row">Child's Cut 12 & Under</th>
                <td>30 mins</td>
                <td>£20.00</td>
              </tr>
              <tr>
                <th scope="row">ONE GRADE ALL OVER</th>
                <td></td>
                <td>£15.00</td>
              </tr>
              <tr>
                <th scope="row">TRIMMING & SHAPING</th>
                <td></td>
                <td>£20.00</td>
              </tr>
              <tr>
                <th scope="row">FULL SHAPING & SHAVE</th>
                <td></td>
                <td>£25.00</td>
              </tr>
            </tbody>
          </Table>
        </TransformYDown>
      </Section>
      <br />
    </>
  );
};

export default Pricing;
