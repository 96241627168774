///////////////////////////////////////////////////////////////////////////////////MODULES
import { FullPage, Section } from "@ibiliaze/react-base";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const ReturnPolicyPage = () => (
  <FullPage className="below-header p-b-m custom-page">
    <Section>
      Last updated January 15, 2023
      <h1>REFUNDS</h1>
      <p>All sales are final and no refund will be issued.</p>
      <h3>QUESTIONS</h3>
      <p>
        If you have any questions concerning our return policy, please contact
        us at:
      </p>
      {c.email.email1}
      <p>
        This return policy was created using Termly's Return and Refund Policy
        Generator.
      </p>
    </Section>
  </FullPage>
);

export default ReturnPolicyPage;
