///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from "react";
import { Col, Row } from "@ibiliaze/reactstrap";
import { Section } from "@ibiliaze/react-base";
import { TextType } from "@ibiliaze/react-text-type";
import { ImageViewer } from "@ibiliaze/react-dropzone";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const Albums = () => {
  // State
  const [imgViewer, setImgViewer] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");

  // Toggle functions
  const toggleImgViewer = (imgSrc) => {
    setImgViewer(!imgViewer);
    setSelectedImg(imgSrc);
  };

  // JSX
  return (
    <Section className="custom-page p-t-xl p-b-xl">
      <h1 className="t-align-c">
        <TextType strings={c.textType}></TextType>
      </h1>

      <Row style={{ overflow: "hidden" }}>
        {c.extras.albums?.map((img, i) => (
          <Col md={4} key={i}>
            <img
              onClick={(_) => toggleImgViewer(img.imgSrc)}
              className="change-img-container change-img"
              alt={i}
              src={img.imgSrc}
            />
            <ImageViewer modal={imgViewer} setModal={setImgViewer} imgSrc={selectedImg} id={i} />
          </Col>
        ))}
      </Row>
    </Section>
  );
};

export default Albums;
